import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import Wrapper from '../styles/utilities/Wrapper';
import CornCard from '../components/modules/CornCard';
import { misc, colors, breakpoints } from '../styles/utilities/settings';

const Archive = ({ pageContext: { title, pathPrefix, group } }) => (
  <Layout title={title} path={pathPrefix}>
    <Wrapper>
      <Listing>
        <h1>{title}</h1>
        {group.map((corn) => (
          <CornCard key={corn._id} content={corn} />
        ))}
      </Listing>
    </Wrapper>
  </Layout>
);

export default Archive;

const Listing = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: ${misc.sectionMobileMargin}px 0 ${misc.sectionMargin - 30}px;
  justify-content: center;

  h1 {
    width: 100%;
    text-align: center;
    margin: 0 0 ${misc.sectionMobileMargin}px;
    color: ${colors.darkBlue};
  }

  > div {
    width: 100%;
    padding: 0 15px 30px;

    @media screen and (min-width: ${breakpoints.ipadPort}px) {
      width: 50%;
    }

    @media screen and (min-width: ${breakpoints.ipadLand}px) {
      width: 25%;
    }
  }
`;
