import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import ImageSizer from '../helpers/ImageSizer';

import { colors } from '../../styles/utilities/settings';

const CornCard = ({ content }) => (
  <SCornCard>
    <Link to={content.slug}>
      <h4>{content.title}</h4>
      <ImageSizer
        width={250}
        height={400}
        src={content.mainImage}
        alt={content.title}
      />
    </Link>
  </SCornCard>
);

export default CornCard;

const SCornCard = styled.div`
  text-align: center;
  max-width: 256px;

  a {
    display: block;
    text-decoration: none;
    max-width: 256px;
    margin: 0 auto;
  }

  h4 {
    margin: 0;
    background-color: ${colors.darkBlue};
    color: ${colors.white};
    font-size: 20px;
    padding: 20px 5px;
  }

  picture {
    margin: 0;
  }
`;
